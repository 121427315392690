<template>
  <div class="container">
    <div class="members">
      <v-row class="">
        <v-col cols="12" class="top-panel px-7">
          <div class="arrow-btn">
            <router-link style="margin: auto" v-if="user &&
              user.roles &&
              user.roles.length > 0 &&
              user.roles[0].employeeRole === 'Root'
              " to="/UserMembers">
              <img src="../assets/img/arrowBack.svg" class="img-pluss-btn" alt="arrow-back" />
            </router-link>
            <router-link style="margin: auto" v-else to="/RestaurantChoose">
              <img src="../assets/img/arrowBack.svg" class="img-pluss-btn" alt="arrow-back" />
            </router-link>
            <h1 class="page-title">Настройки Доставки</h1>
          </div>
          <div class="d-flex align-center">
            <p class="warning-red">Есть несохраненые изменения!</p>
            <button class="btn-save">Удалить Пользователя</button>
            <button class="btn-save">
              Сохранить
              <img class="save-icon" src="../assets/img/btn-save.svg" alt="save" />
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row class="container-margin">
        <div>
          <v-row>
            <v-col cols="12" class="col-padding-top">
              <div class="content-container">
                <v-col cols="12" class="h-padding-select">
                  <h2 class="information-title">варианты доставки</h2>
                </v-col>

                <v-col cols="12" class="row-flex">
                  <v-col cols="5" class="px-0 py-0">
                    <p class="delivery-text">Доставка</p>
                  </v-col>
                  <v-col cols="7" @click="toggleInputMenu" @mouseenter="addBorderClass" @mouseleave="removeBorderClass"
                    ref="inputButton" class="px-0 py-0 border-white" :class="{ 'border-hover': isHovered }">
                    <div class="select-btn border-white" :class="{ 'border-input': showInputMenu }">
                      <p class="option-btn">{{ selectedOption }}</p>
                      <!-- <select v-model="selectedDelivery">
                  <option
                    v-for="item in itemDelivery"
                    :value="item"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select> -->
                      <div class="">
                        <img src="../assets/img/arrow-select.svg" alt="" />
                      </div>

                      <!-- Стрелочка -->
                    </div>
                  </v-col>
                  <div class="input-menu" v-if="showInputMenu" ref="inputMenu">
                    <p class="profile-menu-item" @click="selectOption('Включить')">
                      Включить
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOption('Выключить')">
                      Выключить
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOption('Выключить на час')">
                      Выключить на час
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOption('Выключить на 2 часа')">
                      Выключить на 2 часа
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOption('Выключить на день')">
                      Выключить на день
                    </p>
                  </div>
                </v-col>
                <div class="horizontal-line"></div>
                <v-col cols="12" class="row-flex">
                  <v-col cols="5" class="px-0 py-0">
                    <p class="delivery-text">Навынос</p>
                  </v-col>
                  <v-col cols="7" @click="toggleInputMenuTogo" @mouseenter="addBorderClassTogo"
                    @mouseleave="removeBorderClassTogo" ref="inputButtonTogo" class="px-0 py-0 border-white"
                    :class="{ 'border-hover': isHoveredTogo }">
                    <div class="select-btn border-white" :class="{
              'border-input': showInputMenuTogo,
            }">
                      <p class="option-btn">{{ selectedOptionTogo }}</p>
                      <!-- <select v-model="selectedDelivery">
                  <option
                    v-for="item in itemDelivery"
                    :value="item"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select> -->
                      <div class="">
                        <img src="../assets/img/arrow-select.svg" alt="" />
                      </div>

                      <!-- Стрелочка -->
                    </div>
                  </v-col>
                  <div class="input-menu-togo" v-if="showInputMenuTogo" ref="inputMenuTogo">
                    <p class="profile-menu-item" @click="selectOptionTogo('Включить')">
                      Включить
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOptionTogo('Выключить')">
                      Выключить
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOptionTogo('Выключить на час')">
                      Выключить на час
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOptionTogo('Выключить на 2 часа')">
                      Выключить на 2 часа
                    </p>
                    <div class="divider-modal"></div>
                    <p class="profile-menu-item" @click="selectOptionTogo('Выключить на день')">
                      Выключить на день
                    </p>
                  </div>
                </v-col>
                <div class="horizontal-line margin-special"></div>
                <v-col cols="12" class="h-padding-select pb-0">
                  <h2 class="information-title mb-3">Время доставки</h2>
                  <span class="description-text">Укажите время в которое вы готовы принимать и доставлять
                    заказы</span>
                </v-col>

                <v-col cols="12" class="h-padding-select">
                  <div class="time-delivery-container">
                    <div class="start-container">
                      <p class="time-start mb-0">Время начала</p>
                      <input type="text" class="special-border" v-model="timeStart" @input="formatTime"
                        @keydown="handleBackspace" placeholder="00:00" maxlength="5" />
                    </div>
                    <div class="end-container">
                      <p class="time-end mb-0">Время окончания</p>
                      <input type="text" class="special-border" v-model="timeEnd" @input="formatTime"
                        @keydown="handleBackspace" placeholder="00:00" maxlength="5" />
                    </div>
                  </div>
                </v-col>
                <div class="horizontal-line mt-5"></div>
            <v-col cols="12" class="h-padding-select">
              <span class="description-text"
                >Укажите дни недели в которые вы готовы принимать и доставлять
                заказы</span
              >
              <div class="weekDays-selector mt-4">
                <input
                  type="checkbox"
                  id="weekday-mon"
                  v-model="selectedDays.Monday"
                  class="weekday"
                />
                <label for="weekday-mon">П</label>
                <input
                  type="checkbox"
                  id="weekday-tue"
                  v-model="selectedDays.Tuesday"
                  class="weekday"
                />
                <label for="weekday-tue">В</label>
                <input
                  type="checkbox"
                  id="weekday-wed"
                  v-model="selectedDays.Wednesday"
                  class="weekday"
                />
                <label for="weekday-wed">С</label>
                <input
                  type="checkbox"
                  id="weekday-thu"
                  v-model="selectedDays.Thursday"
                  class="weekday"
                />
                <label for="weekday-thu">Ч</label>
                <input
                  type="checkbox"
                  id="weekday-fri"
                  v-model="selectedDays.Friday"
                  class="weekday"
                />
                <label for="weekday-fri">П</label>
                <input
                  type="checkbox"
                  id="weekday-sat"
                  v-model="selectedDays.Saturday"
                  class="weekday"
                />
                <label for="weekday-sat">С</label>
                <input
                  type="checkbox"
                  id="weekday-sun"
                  v-model="selectedDays.Sunday"
                  class="weekday"
                />
                <label for="weekday-sun">В</label>
              </div>
              <p class="days-choose mt-3">{{ selectedDayNames }}</p>
            </v-col>
            <v-col cols="12" class="h-padding-select">
              <h2 class="information-title mb-3">Стоимость доставки</h2>
              <span class="description-text"
                >Укажите стоимость доставки, которая будет отображаться при
                заказе гостя</span
              >
            </v-col>

            <v-col cols="12" class="">
              <div class="zone-container">
                <p class="time-start mb-0">Зона 1</p>
                <input
                  type="text"
                  class="zone-price"
                  v-model="zoneOne"
                  @input="formatZoneInput('zoneOne')"
                  @keydown="clearCurrencySymbol('zoneOne', $event)"
                  placeholder="0 ₸"
                />
              </div>
            </v-col>
            <div class="horizontal-line"></div>
            <v-col cols="12" class="">
              <div class="">
                <div class="zone-container">
                  <p class="time-start mb-0">Зона 2</p>
                  <input
                    type="text"
                    class="zone-price"
                    v-model="zoneTwo"
                    @input="formatZoneInput('zoneTwo')"
                    @keydown="clearCurrencySymbol('zoneTwo', $event)"
                    placeholder="0 ₸"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="h-padding-select">
                    <h2 class="information-title mb-3">минимальная сумма заказа</h2>
                    <span class="description-text">Вы можете указать минимальную сумму заказа для бесплатной
                      доставки</span>
                  </v-col>

                  <v-col cols="12" class="px-5">
                    <div class="zone-container">
                      <p class="time-start mb-0">Сумма в тг</p>
                      <input type="text" class="zone-price" v-model="minOrder" @input="formatminOrderInput('minOrder')"
                        @keydown="clearCurrencySymbolMinOrder($event)" placeholder="0 ₸" />
                    </div>
                  </v-col>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}

.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0;
  /* Пример: изменение цвета фона при наведении */
  color: #333;
  /* Пример: изменение цвета текста при наведении */
  cursor: pointer;
  /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}

.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}

.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu-togo {
  position: absolute;
  bottom: 56%;
  left: 20.1%;
  width: 272px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}

.input-menu {
  position: absolute;
  bottom: 62%;
  left: 20.1%;
  width: 272px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}

.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.minOrder-margin-bottom {
  margin-bottom: 69px;
}

.zone-price:focus {
  outline: none;
}

.zone-price {
  width: 70%;
  height: 37px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  border: none;
}

.zone-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.days-choose {
  color: var(--05, #9f9f9f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type="checkbox"]+label {
  display: inline-block;
  border-radius: 50%;
  background: #c1c1c1;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  color: white;
  margin-right: 15px;
}

.weekDays-selector input[type="checkbox"]:checked+label {
  background: #2ad705;
  color: #ffffff;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

.special-border {
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
input:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}
input:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.description-text {
  color: #9f9f9f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-flex {
  flex-direction: row;
  display: flex;
}

.margin-special {
  margin-bottom: 37px;
}

.h-padding-select {
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 18px;
  margin-left: 18px;
}

.custom-select {
  position: relative;
  width: 294px;
  /* Настройте нужную ширину для выпадающего списка */
  cursor: pointer;
}

.custom-select select {
  width: 100%;
  padding: 8px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  cursor: pointer;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  /* Цвет стрелочки */
}

.inut-container {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 50% !important;
}

.row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
}

.delivery-text {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  /* margin-right: 132px; */
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.col-padding-top {
  padding-top: 0px;
}

.amount {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

.avable-balance {
  color: var(--unnamed, #5e5e5e);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 200px;
}

.total-count-delivery {
  color: var(--unnamed, #383838);
  font-family: Inter;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

.second-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 6px;
  padding-left: 18px;
  padding-right: 25px;
  padding-bottom: 22px;
}

.sum-text {
  margin-bottom: 0px;
  color: var(--unnamed, #383838);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 25px;
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 23px;
}

.information-title-text {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}

.information-title-text-special {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
}

.container-margin {
  margin: auto;
}

.page-title {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0%;
  text-align: left;
  margin-left: 16px;
}

.warning-red {
  color: rgb(228, 19, 19);
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}

.btn-save {
  display: flex;
  align-items: center;
  padding: 5px 18px 5px 18px;
  margin-left: 37px;
  cursor: pointer;
  border-radius: 5px;
  background: rgb(50, 60, 77);
  height: 36px;
  color: white;
  margin-left: 20px;
}

.save-icon {
  margin-left: 5px;
}

.mt-top {
  margin-top: 23px;
  margin-bottom: 0px;
}

.uploaded-image-container {
  cursor: pointer;
}

.container-img {
  max-width: 100%;
  /* Гарантировать, что изображение не будет больше контейнера */
  max-height: 100%;
}

.my-dialog {
  background: white;
  border-width: 0px 30px 30px 30px;
  border-style: none solid solid solid;
  border-color: transparent white white white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  /* Скрыть изображение, которое выходит за пределы контейнера */
}

.close-btn {
  cursor: pointer;

  text-align: end;
}

.modal-image {
  width: 100%;
  /* Сделать изображение 100% ширины контейнера */
  height: auto;
  cursor: pointer;
  /* Сохранить соотношение сторон изображения */
}

.content-container-img {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
  /* height: 270px; */
}

.col-content-img {
  display: flex;
  /* align-items: center; */
}

.product-item__image {
  width: 100px;
  height: 100px;
}

.img-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.uploaded-image-logo {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.horizonmargin-bottom {
  margin-bottom: 39px;
}

.zone-price:focus {
  outline: none;
}

.zone-price {
  width: 70%;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  border: none;
  padding-left: 12px;
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.zone-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0;
  /* Пример: изменение цвета фона при наведении */
  color: #333;
  /* Пример: изменение цвета текста при наведении */
  cursor: pointer;
  /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}

.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}

.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}


.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}

.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-left: 5px;
}

.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}

input:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}

input:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

textarea:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}

textarea:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

.full-text-input,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}

.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.margin-logo-divider {
  margin-bottom: 48px;
}

.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-file-upload {
  cursor: pointer;
  margin-left: 18px;
}

.logo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded-image-logo {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.uploaded-image-header {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.uploaded-image-photo-rest {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.work-margin-img {
  margin-bottom: 15px;
}

.work-img {
  width: 22px;
  height: 22px;
}

.col-img {
  flex-direction: column-reverse;
  display: flex;
}

.work-margin {
  padding-bottom: 15px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}

.horizontal-line-img {
  height: 1px;
  background: #848484;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 29px;
  margin-bottom: 44px;
}

.tel-pointer {
  cursor: pointer;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}

.col-description {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  min-height: 45px;
  /* Минимальная высота для textarea */
  padding: 10px;
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}

.content-container {
  border-radius: 8px;
  background: rgb(255, 255, 255);
  max-width: 533px;
  padding: 10px 10px 20px 10px;
}

::v-deep.v-tabs .v-tabs-bar .v-tabs-slider {
  background-color: #00b800 !important;
  /* Укажите желаемый цвет для индикатора */
}

::v-deep .v-tab:before {
  background-color: gray;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

::v-deep .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-tab {
  caret-color: #ff0000 !important;
  color: #333;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 1.1rem;
  font-weight: 600;
  font-style: normal;
  justify-content: center;
  letter-spacing: normal;
  line-height: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: "Inter";
}

.breadcrums-click {
  cursor: pointer;
}

.breadcrums-title {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  text-decoration: none;
}

.breadcrums-container {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

::v-deep .v-input__slot {
  border-style: none;
  margin-bottom: 0px;
}

.title-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  /* width: 56px; */
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .top-panel .container {
          width: 100%;
          padding: 0px !important;
          margin-right: 0 !important;
          margin-left: 0 !important;
        } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}

.top-panel {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

@media (min-width: 1264px) {
  .container {
    /* //1185px дефолтное название если что то ломаться начнется на маленьких мониторах */
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {

  /* //900 дефолтное название если что то ломаться начнется на маленьких мониторах */
  .container {
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "DeliveryPage",
  directives: { mask },
  // props: {
  //   requestData: {},
  //   responseData: {},
  // },

  created() {
    // this.uploadedImageLogo =
    //   process.env.VUE_APP_API_HOST + this.responseData.logo.imagePath;
  },

  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideTogo);
  },

  data() {
    return {
      isHovered: false,
      isHoveredTogo: false,
      selectedOption: "",
      selectedOptionTogo: "", // Изначально выбранное значение
      zoneOne: "",
      zoneTwo: "",
      minOrder: "",
      showInputMenu: false,
      showInputMenuTogo: false,

      selectedDays: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,

        // Другие дни недели
      },
      timeStart: "",
      timeEnd: "",
      roleTranslations: {
        Owner: "Владелец",
        Administrator: "Администратор",
      },
    };
  },

  methods: {
    getUserMember() {
      this.$http
        .get(`/api/Product/${this.$route.params.productId}`)
        .then((response) => {
          this.product = response.data;
          this.productName = response.data.name;
          this.description = response.data.description;
          this.selectedOption = response.data.category.name;
          this.price = response.data.price + "₸";
          this.loading = true;
          if (response.data.images.length && response.data.images[2]) {
            this.uploadedImageLogo =
              process.env.VUE_APP_API_HOST + response.data.images[2].imagePath;
          }
          console.log(this.uploadedImageLogo);
        })
        .catch((error) => {
          alert(error);
        });
    },
    formatZoneInput(zone) {
      // Удаляем все символы, кроме цифр
      let value = this[zone].replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this[zone] = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем двоеточие в конце
      value += "₸";

      // Обновляем значение модели
      this[zone] = value;
    },
    formatminOrderInput() {
      // Удаляем все символы, кроме цифр
      let value = this.minOrder.replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this.minOrder = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем двоеточие в конце
      value += "₸";

      // Обновляем значение модели
      this.minOrder = value;
    },
    clearCurrencySymbol(zone, event) {
      if (event.key === "Backspace" && this[zone].length > 0) {
        this[zone] = this[zone].slice(0, -1); // Удаляем последний символ
      }
    },

    clearCurrencySymbolMinOrder(event) {
      if (event.key === "Backspace" && this.minOrder.length > 0) {
        this.minOrder = this.minOrder.slice(0, -1); // Удаляем последний символ
      }
    },
    formatTime() {
      // Удалите все символы, кроме цифр
      this.timeStart = this.timeStart.replace(/\D/g, "");

      this.timeEnd = this.timeEnd.replace(/\D/g, "");

      // Ограничьте ввод до 4 символов
      if (this.timeStart.length > 4) {
        this.timeStart = this.timeStart.substring(0, 4);
      }

      if (this.timeEnd.length > 4) {
        this.timeEnd = this.timeEnd.substring(0, 4);
      }

      // Добавьте двоеточие после первых двух символов
      if (this.timeStart.length >= 2) {
        this.timeStart =
          this.timeStart.substring(0, 2) + ":" + this.timeStart.substring(2);
      }

      if (this.timeEnd.length >= 2) {
        this.timeEnd =
          this.timeEnd.substring(0, 2) + ":" + this.timeEnd.substring(2);
      }
    },
    handleBackspace(event) {
      if (event.key === "Backspace" && this.timeStart.length > 0) {
        this.timeStart = this.timeStart.slice(0, -1); // Удаляем последний символ
      }

      if (event.key === "Backspace" && this.timeEnd.length > 0) {
        this.timeEnd = this.timeEnd.slice(0, -1); // Удаляем последний символ
      }
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    hidePlaceholder(inputName) {
      if (inputName === "productName") {
        this.isproductNameFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },
    selectOptionTogo(option) {
      this.selectedOptionTogo = option;
      this.toggleInputMenuTogo(); // Вызовите метод для скрытия меню (если это требуется)
    },
    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.showInputMenu = false;
      }
    },
    handleClickOutsideTogo(event) {
      const inputButtonTogo = this.$refs.inputButtonTogo;
      const inputMenuTogo = this.$refs.inputMenuTogo;

      if (
        inputButtonTogo &&
        inputMenuTogo &&
        !inputButtonTogo.contains(event.target) &&
        !inputMenuTogo.contains(event.target)
      ) {
        this.showInputMenuTogo = false;
      }
    },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },
    toggleInputMenuTogo() {
      this.isHoveredTogo = false;
      this.showInputMenuTogo = !this.showInputMenuTogo;
    },
    addBorderClassTogo() {
      this.isHoveredTogo = true;
    },
    removeBorderClassTogo() {
      this.isHoveredTogo = false;
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
    selectedDayNames() {
      const selectedDays = [];
      if (this.selectedDays.Monday) selectedDays.push("Понедельник");
      if (this.selectedDays.Tuesday) selectedDays.push("Вторник");
      if (this.selectedDays.Wednesday) selectedDays.push("Среда");
      if (this.selectedDays.Thursday) selectedDays.push("Четверг");
      if (this.selectedDays.Friday) selectedDays.push("Пятница");
      if (this.selectedDays.Saturday) selectedDays.push("Суббота");
      if (this.selectedDays.Sunday) selectedDays.push("Воскресенье");

      if (selectedDays.length === 7) {
        return "Каждый день";
      } else {
        return selectedDays.join(", ");
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
  },

  watch: {
    productName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("productNameTextarea");
      });
    },
    description() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionTextarea");
      });
    },
  },
};
</script>